<template>
  <body-with-header title="ثبت اطلاعات تکمیلی زمین" :loading="loading">
    <div class="main-card py-4">
      <CModal
        title="عدم ثبت محدوده زمین"
        color="warning"
        :show.sync="areaModal"
      >
        <h6>
          محدوده زمین مشخص نگردیده است!
          <br/>
          لطفا ابتدا محدوده زمین را تعیین نمایید.
        </h6>
        <template #footer>
          <CButton
            @click="
              otpModal = false;
              goSetArea();
            "
            color="warning"
          >تعیین محدوده زمین
          </CButton>
        </template>
      </CModal>
      <CForm class="vertical-g-20">
        <CRow>
          <CCol v-if="selectFarmerState" md="4" class="align-self-center mb-3 mb-md-2">
                          <span for="">
                            کشاورز(مالک زمین)
                            <span style="color: red">*</span>
                          </span>
            <v-select
              dir="rtl"
              v-model="selectLegalEntityFarmer"
              :options="farmerSource"
              placeholder=" انتخاب کشاورز..."
            ></v-select>
          </CCol>
          <CCol :md="selectFarmerState ? '4' : '8'">
            <label for="">
              نام انتخابی زمین
              <span style="color: red">*</span>
            </label>
            <CInput
              :lazy="false"
              :value.sync="$v.form.title.$model"
              :isValid="checkIfValid('title')"
              autocomplete="given-name"
              invalidFeedback="این فیلد اجباری است"
            />
          </CCol>
          <CCol md="4">
            <label>
              تاریخ کشت
              <span style="color: red">*</span>
            </label>
            <date-picker
              color="var(--secondary)"
              v-model="form.cultivationsDate"
            ></date-picker>
          </CCol>
        </CRow>
        <CRow>
          <CCol md="4" class="align-self-center mb-3 mb-md-2">
                          <span for="">
                            نوع محصول
                            <span style="color: red">*</span>
                          </span>
            <v-select
              dir="rtl"
              :isValid="checkIfValid('productTypeId')"
              v-model="selectedProductType"
              :options="productTypesSource"
              required
              invalidFeedback="این فیلد اجباری است"
              placeholder="انتخاب نوع محصول..."
            ></v-select>
          </CCol>
          <CCol md="4" class="mb-3 mb-md-0">
                          <span for="">
                            نام محصول
                            <span style="color: red">*</span>
                          </span>
            <v-select
              dir="rtl"
              :isValid="checkIfValid('productId')"
              v-model="selectedProduct"
              :options="productsSource"
              required
              invalidFeedback="این فیلد اجباری است"
              placeholder="انتخاب محصول..."
            ></v-select>
          </CCol>
          <CCol md="4" class="mb-3 mb-md-0">
                          <span for="">
                            نوع کشت
                            <span style="color: red">*</span>
                          </span>
            <v-select
              dir="rtl"
              :isValid="checkIfValid('cultivationsType')"
              v-model="selectedCultivationsType"
              :options="cultivationsTypeSource"
              required
              invalidFeedback="این فیلد اجباری است"
              placeholder="انتخاب نوع کشت..."
            ></v-select>
          </CCol>
        </CRow>
        <CRow>
          <CCol md="4" class="mb-3 mb-md-0">
                          <span for="">
                            کشور
                            <span style="color: red">*</span>
                          </span>
            <v-select
              dir="rtl"
              v-model="selectedCountry"
              :options="countriesSource"
              required
              invalidFeedback="این فیلد اجباری است"
              placeholder="انتخاب کشور..."
            />
          </CCol>
          <CCol md="4" class="mb-3 mb-md-0">
                          <span for="">
                            استان
                            <span style="color: red">*</span>
                          </span>
            <v-select
              dir="rtl"
              :isValid="checkIfValid('stateId')"
              v-model="selectedState"
              :options="statesSource"
              required
              invalidFeedback="این فیلد اجباری است"
              placeholder="انتخاب استان ها..."
            ></v-select>
          </CCol>
          <CCol md="4" class="mb-3 mb-md-0">
                          <span for="">
                            شهرستان
                            <span style="color: red">*</span>
                          </span>
            <v-select
              dir="rtl"
              :isValid="checkIfValid('cityId')"
              v-model="selectedCity"
              :options="cityOptions"
              required
              invalidFeedback="این فیلد اجباری است"
              placeholder="انتخاب شهرستان ها..."
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol md="6">
            <label for="">
              بخش
              <span style="color: red">*</span>
            </label>
            <CTextarea
              :value.sync="$v.form.district.$model"
              :isValid="checkIfValid('district')"
              rows="3"
              required
              invalidFeedback="این فیلد اجباری است"
            />
          </CCol>
          <CCol md="6">
            <label for="">
              روستا
              <span style="color: red">*</span>
            </label>
            <CTextarea
              :value.sync="$v.form.village.$model"
              :isValid="checkIfValid('village')"
              rows="3"
              required
              invalidFeedback="این فیلد اجباری است"
            />
          </CCol>
        </CRow>
        <form-action-buttons
          @ignoreForm="goLogin"
          @submitForm="submit"/>
      </CForm>
    </div>
  </body-with-header>
</template>

<script>
import {mapActions, mapState} from "vuex";
import useVuelidate from "@vuelidate/core";

import {validationMixin} from "vuelidate";
import {minLength, required} from "vuelidate/lib/validators";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import cultivationsType from "../../store/data/enum/cultivationsType.json";
import {getCurrentUser} from "../../utils/index";
import agriculturalType from "../../store/data/enum/agriculturalType";
import gardenType from "../../store/data/enum/gardenType";
import BodyWithHeader from "../../components/bodyWithHeader.vue";
import FormActionButtons from "../../components/formActionButtons.vue";

export default {
  setup() {
    return {v$: useVuelidate()};
  },
  name: "farmAddMapDetail",
  components: {
    FormActionButtons,
    BodyWithHeader,
    vSelect,
  },
  data() {
    return {
      areaModal: false,
      successModal: false,
      form: this.getEmptyForm(),
      cultivationsTypeSource: cultivationsType,
      selectedCultivationsType: "",

      countriesSource: [],
      selectedCountry: "",
      statesSource: [],
      selectedState: "",
      cityOptions: [],
      selectedCity: "",

      productTypesSource: [],
      selectedProductType: "",
      productsSource: [],
      selectedProduct: "",
      loading: false,

      selectFarmerState: false,
      selectLegalEntityFarmer: null,
      farmerSource: [],
    };
  },
  computed: {
    ...mapState({
      isVerifyAreaString: (state) => state.farm.areaString,
    }),

    isValid() {
      return !this.$v.form.$invalid;
    },
  },
  mixins: [validationMixin],

  watch: {
    selectedProductType: function () {
      this.productsSource = [];
      this.selectedProduct = "";
      if (this.selectedProductType.id > 0) {
        this.getProductWithType(this.selectedProductType.id);
      }
    },
    selectedCountry: function () {
      this.statesSource = [];
      this.selectedState = "";
      this.cityOptions = [];
      this.selectedCity = "";

      if (this.selectedCountry.id > 0) {
        this.GetProvinceWithCountryId(this.selectedCountry.id);
      }
    },
    selectedState: function () {
      this.cityOptions = [];
      this.selectedCity = "";
      if (this.selectedState.id > 0) {
        this.GetCityWithProvinceId(this.selectedState.id);
      }
    },
  },

  validations: {
    form: {
      userType: {},
      userId: {},
      farmerId: {},
      title: {
        required,
        minLength: minLength(1),
      },
      cultivationsDate: {
        required,
      },
      productTypeId: {
        required,
        minLength: minLength(1),
      },
      productId: {
        required,
        minLength: minLength(1),
      },
      cultivationsType: {
        required,
        minLength: minLength(1),
      },
      stateId: {
        required,
      },
      cityId: {
        required,
      },
      district: {
        required,
      },
      village: {
        required,
      },
    },
  },
  methods: {
    ...mapActions("farm", [
      "addFarm",
      "GetAllProductType",
      "GetProductWithType",
      "GetAllCountry",
      "GetProvinceWithCountry",
      "GetCityWithProvince",
      "GetAllLegalEntityFarmer"
    ]),
    reset() {
      this.$refs.form.resetValidation();
    },

    getProductNameType() {
      if (this.selectedProductType.value === 2) {
        this.productTypeNameSource = agriculturalType;
      } else {
        this.productTypeNameSource = gardenType;
      }
    },
    async submit() {
      let logedInUser = getCurrentUser();
      this.$v.form.userType.$model = logedInUser.type;
      this.$v.form.productTypeId.$model = this.selectedProductType.id;
      this.$v.form.productId.$model = this.selectedProduct.id;
      this.$v.form.cultivationsType.$model =
        this.selectedCultivationsType.value;
      this.$v.form.stateId.$model = this.selectedState.id;
      this.$v.form.cityId.$model = this.selectedCity.id;
      this.$v.form.farmerId.$model = this.selectLegalEntityFarmer == null ? null : this.selectLegalEntityFarmer.id;
      //cultivationsDate
      this.$v.$touch();
      this.v$.$validate();

      if (this.v$.$error) {
        this.$notify({
          title: "پیام سیستم",
          text: "اطلاعات وارد شده معتبر نیستند <br /><br />",
          type: "error",
        });
        return;
      }

      if (!this.isVerifyAreaString) {
        this.areaModal = true;
        return;
      }
      this.loading = true;
      const {form} = this;
      let result = await this.addFarm({farm: form});
      this.loading = false;
      if (result.succeeded) {
        this.$notify({
          title: "پیام سیستم",
          text: `${result.message ? result.message : result.data.message} <br /><br />`,
          type: "success",
        });
        this.$router.push({name: "صفحه اصلی"});
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `${result.message} <br /><br />`,
          type: "error",
        });
      }
    },

    checkIfValid(fieldName) {
      const field = this.$v.form[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },

    validate() {
      this.$v.$touch();
    },
    goLogin() {
      // named route
      this.$router.push({name: "نقشه های ماهواره ای زمین"});
    },
    goSetArea() {
      // named route
      this.$router.push({name: "تعیین محدوده زمین جدید"});
    },
    getEmptyForm() {
      return {
        userType: "",
        title: "",
        cultivationsDate: "",
        productTypeId: "",
        productId: "",
        cultivationsType: "",
        stateId: "",
        cityId: "",
        farmerId: "",
        village: "",
        district: "",
        KMLfile: null,
      };
    },
    async getAllLegalEntityFarmer() {
      var result = await this.GetAllLegalEntityFarmer();
      this.farmerSource = result.data.map((item) => {
        return {
          id: item.farmerId,
          label: `${item.firstName} ${item.lastName}`,
        };
      });
    },
    async getAllProductType() {
      var result = await this.GetAllProductType({pageNumber: 0, pageSize: 0});
      this.productTypesSource = result.map((item) => {
        return {
          id: item.id,
          label: item.name,
        };
      });
    },
    async getProductWithType(typeId) {
      var result = await this.GetProductWithType({productTypeId: typeId});
      this.productsSource = result.map((item) => {
        return {
          id: item.id,
          label: item.name,
        };
      });
    },
    async getAllCountry() {
      var result = await this.GetAllCountry({pageNumber: 0, pageSize: 0});
      this.countriesSource = result.map((item) => {
        return {
          id: item.id,
          label: item.name,
        };
      });
    },
    async GetProvinceWithCountryId(countryId) {
      var result = await this.GetProvinceWithCountry({countryId: countryId});
      this.statesSource = result.map((item) => {
        return {
          id: item.id,
          label: item.name,
        };
      });
    },
    async GetCityWithProvinceId(provinceId) {
      var result = await this.GetCityWithProvince({stateId: provinceId});
      this.cityOptions = result.map((item) => {
        return {
          id: item.id,
          label: item.name,
        };
      });
    },

    async fetchData() {
      await this.getAllProductType();
      await this.getAllCountry();
    },
  },
  mounted() {
    this.fetchData();
    if (getCurrentUser().type == "LegalEntity") {
      this.getAllLegalEntityFarmer();
      this.selectFarmerState = true;
    } else {
      this.selectFarmerState = false;
    }
  },
};
</script>
<style>

</style>
